<template>
  <div class="home">
    <div class="content">
      <!-- 背景图 -->
      <div class="img-back testdrive">
        <div class="btn" @click="toTestdrive">预约试驾</div>
        <img src="/static/home/1.png" alt="" />
      </div>
      <div class="img-back testdrive">
        <div class="btn" @click="toCarlist">立即订购</div>
        <img src="/static/home/2.png" alt="" />
      </div>
      <div class="img-back custom">
        <div class="btn" @click="toCustom">开启定制之旅</div>
        <img src="/static/home/3.png" alt="" />
      </div>
      <div class="img-back">
        <img src="/static/home/4.png" alt="" />
      </div>
      <el-carousel ref="father">
        <div class="father-height" ref="son" >
          <img src="/static/home/swiper-1.png" alt="">
        </div>
        <el-carousel-item v-for="item in swiper" :key="item">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="img-back">
        <img src="/static/home/5.png" alt="" />
      </div>
      <div class="img-back">
        <img src="/static/home/6.png" alt="" />
      </div>
      <div class="img-back">
        <img src="/static/home/7.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// import { addressList, addCustomizeRecForPc } from "@/api/index.js";
export default {
  data() {
    return {
      baseurl: "https://www.yuexinche.com",
      swiper: ["/static/home/swiper-1.png", "/static/home/swiper-2.png"],
    };
  },
  mounted() {
  },
  methods: {
    toTestdrive() {
      this.$router.push({
        path:'/testdrive'
      })
    },
    toCarlist() {
      this.$router.push({
        path:'/carlist'
      })
    },
    toCustom() {
      this.$router.push({
        path:'/custom'
      })
    }
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  .content {
    width: 100%;
    min-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .img-back {
      width: 100%;
      margin: 0 auto;
      img {
        width: 100%;
        display: inherit;
      }
    }
    .testdrive {
      position: relative;
      .btn {
        cursor: pointer;
        font-size: 18px;
        color:#f2f2f2;
        text-align: center;
        line-height: 47px;
        position: absolute;
        width: 146px;
        height: 47px;
        border: 2px solid #f2f2f2;
        border-radius: 5px;
        left: 19%;
        top: 38%;
        background-color: rgba(0,0,0,0.8);
        &:hover{
          background-color: rgb(99, 95, 95);
        }
      }
    }
    .custom{
      position: relative;
      .btn {
        cursor: pointer;
        font-size: 18px;
        color:#f2f2f2;
        text-align: center;
        line-height: 47px;
        position: absolute;
        width: 227px;
        height: 47px;
        border: 2px solid #f2f2f2;
        border-radius: 5px;
        left: calc(50% - 113px);
        top: 60%;
        background-color: rgba(0,0,0,0.8);
        &:hover{
          background-color: rgb(99, 95, 95);
        }
      }
    }
    /deep/ .el-carousel__container {
      margin: 0 aut0;
      overflow: hidden;
      height: auto;
      .father-height{
        width: 100%;
        overflow: hidden;
        img{
          width: 100%;
          display: inherit;
          opacity: 0;
        }
      }
      .el-carousel__item {
        height: auto;
        img {
          width: 100%;
          display: inline-block;
        }
      }
    }
  }
}
</style>
